<template>
  <div>
    <van-nav-bar title="现金缴费" left-arrow @click-left="$router.go(-1)" />
    <van-cell-group>
      <van-field v-model.trim="price" label="金额(元)" readonly />
      <van-field
        v-model.trim="value"
        label="缴费凭证编号"
        placeholder="请输入缴费凭证编号"
        :formatter="formatter"
      />
    </van-cell-group>
    <div class="d-flex row-around" style="margin: 16px">
      <van-button type="info" @click="pay"> 确认支付 </van-button>
      <van-button plain @click="$router.go(-1)"> 切换支付方式 </van-button>
    </div>
  </div>
</template>
<script>
import { amend } from "@/utils/func.js";
import { Toast } from "vant";
import { payCashBill } from "@/apis/water-payment.js";
export default {
  data() {
    return {
      value: "",
      id: "",
      price: "",
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.price = amend(this.$route.query.price);
  },
  methods: {
    formatter(val) {
      return val.replace(/[\u4E00-\u9FA5]/g, "");
    },
    pay() {
      let toast = Toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });

      payCashBill({
        recordId: this.id,
        chargingVoucher: this.value,
        payType: 1,
      })
        .then((res) => {
          if (res.succeeded) {
            toast.clear();
            if (res.data) {
              Toast.success("操作成功！");
              setTimeout(() => {
                this.$router.replace({
                  path: "/list",
                });
              }, 1500);
            }
          }
        })
        .catch(() => {
          toast.clear();
        });
    },
  },
};
</script>
